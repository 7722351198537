body {
  max-width: 40em;
  color: #fafafa;
  background-color: #212121;
  margin-left: 2em;
  font-family: Rubik, sans-serif;
  line-height: 1.6;
}

a {
  color: #ff0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

code {
  font-family: Source Code Pro, monospace;
}

h1 {
  font-family: Major Mono Display, monospace;
  font-size: 9vw;
  line-height: initial;
  text-transform: lowercase;
}

h1:first-letter {
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 4em;
  }
}

h2 {
  border-bottom: 1px solid #eaecef;
}

li {
  list-style-type: square;
}

/*# sourceMappingURL=index.abc8052c.css.map */
